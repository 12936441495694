import {
  MarketNameEnum,
  MarketNameTypeEnum,
  BankAccountTypeEnum,
  CurrencyEnum,
  HomeRentalTypeEnum,
  IdentificationTypeEnum,
} from '@qasa/graphql'

import type { LanguageCode } from '../helpers/i18n'

import type { BrandType } from './index.types'
import { currentBrand } from '.'

type Configuration = {
  name: string
  allowedCurrencies: CurrencyEnum[]
  defaultLanguage: LanguageCode
  allowedLanguages: LanguageCode[]
  countryCode: 'se' | 'fi' | 'fr' | 'international'
  country: 'Sweden' | 'Finland' | 'France' | 'international'
  market?: MarketNameTypeEnum
  trustpilotId?: string
  availableMarkets: Partial<Record<MarketNameEnum, { availableRentalTypes: HomeRentalTypeEnum[] }>>
  bankAccountTypes: Array<BankAccountTypeEnum>
  availableIdVerifications: IdentificationTypeEnum[]
}

const brandConfigurations: Record<BrandType, Configuration> = {
  blocket: {
    name: 'Blocket Bostad',
    allowedCurrencies: [CurrencyEnum.SEK],
    allowedLanguages: ['sv', 'en'],
    defaultLanguage: 'sv',
    availableIdVerifications: [IdentificationTypeEnum.se_bank_id],
    countryCode: 'se',
    country: 'Sweden',
    market: MarketNameTypeEnum.sweden,
    trustpilotId: '5e1464015b237300018c9a5d',
    availableMarkets: {
      [MarketNameEnum.sweden]: {
        availableRentalTypes: [HomeRentalTypeEnum.long_term, HomeRentalTypeEnum.vacation],
      },
    },
    bankAccountTypes: [
      BankAccountTypeEnum.bank_account,
      BankAccountTypeEnum.international,
      BankAccountTypeEnum.bank_giro,
      BankAccountTypeEnum.plus_giro,
      BankAccountTypeEnum.address,
    ],
  },
  qasa: {
    name: 'Qasa',
    allowedCurrencies: [CurrencyEnum.SEK],
    allowedLanguages: ['sv', 'en'],
    defaultLanguage: 'sv',
    availableIdVerifications: [IdentificationTypeEnum.se_bank_id],
    countryCode: 'se',
    country: 'Sweden',
    market: MarketNameTypeEnum.sweden,
    availableMarkets: {
      [MarketNameEnum.sweden]: {
        availableRentalTypes: [HomeRentalTypeEnum.long_term, HomeRentalTypeEnum.vacation],
      },
    },
    bankAccountTypes: [
      BankAccountTypeEnum.bank_account,
      BankAccountTypeEnum.international,
      BankAccountTypeEnum.bank_giro,
      BankAccountTypeEnum.plus_giro,
      BankAccountTypeEnum.address,
    ],
  },
  qasa_finland: {
    name: 'Qasa',
    allowedCurrencies: [CurrencyEnum.EUR],
    allowedLanguages: ['fi'],
    defaultLanguage: 'fi',
    availableIdVerifications: [IdentificationTypeEnum.fi_tupas],
    countryCode: 'fi',
    country: 'Finland',
    market: MarketNameTypeEnum.finland,
    availableMarkets: {
      [MarketNameEnum.finland]: {
        availableRentalTypes: [HomeRentalTypeEnum.long_term],
      },
    },
    bankAccountTypes: [BankAccountTypeEnum.international, BankAccountTypeEnum.address],
  },
  qasa_france: {
    name: 'Qasa',
    allowedCurrencies: [CurrencyEnum.EUR],
    // TODO: Add french translations
    allowedLanguages: ['en', 'fr'],
    defaultLanguage: 'fr',
    availableIdVerifications: [IdentificationTypeEnum.onfido],
    countryCode: 'fr',
    country: 'France',
    market: MarketNameTypeEnum.france,
    availableMarkets: {},
    bankAccountTypes: [BankAccountTypeEnum.international],
  },
  dotcom: {
    name: 'Qasa',
    allowedCurrencies: [CurrencyEnum.EUR, CurrencyEnum.SEK, CurrencyEnum.NOK],
    allowedLanguages: ['sv', 'en'],
    defaultLanguage: 'en',
    countryCode: 'international',
    country: 'Sweden',
    availableMarkets: {
      [MarketNameEnum.sweden]: {
        availableRentalTypes: [HomeRentalTypeEnum.long_term, HomeRentalTypeEnum.vacation],
      },
      [MarketNameEnum.finland]: {
        availableRentalTypes: [HomeRentalTypeEnum.long_term],
      },
      [MarketNameEnum.norway]: {
        availableRentalTypes: [HomeRentalTypeEnum.long_term],
      },
    },
    bankAccountTypes: [
      BankAccountTypeEnum.bank_account,
      BankAccountTypeEnum.international,
      BankAccountTypeEnum.bank_giro,
      BankAccountTypeEnum.plus_giro,
      BankAccountTypeEnum.address,
    ],
    availableIdVerifications: [
      IdentificationTypeEnum.se_bank_id,
      IdentificationTypeEnum.fi_tupas,
      IdentificationTypeEnum.no_bank_id,
      IdentificationTypeEnum.onfido,
    ],
  },
}

export const BRAND_CONFIG = brandConfigurations[currentBrand]
